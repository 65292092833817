import React, { useState } from 'react';
import './RegisterSectionComponent.css';

const RegisterSectionComponent = ({ data }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);

  const handleRegisterClick = () => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError('The email address is incorrect.');
    } else {
      setError('');
      setIsRegistered(true); // Hide input and button, show success message
    }
  };

  return (
    <div className="content-section">
      <div className="text-cent-section">
        <h4>{data.title}</h4>
        {data.text.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      {!isRegistered ? (
        <div className="register-section">
          <input
            type="email"
            placeholder="example@domain.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
          />
          <button onClick={handleRegisterClick} className="register-button">
            Register product
          </button>
          {error && <p className="error-message">{error}</p>}
        </div>
      ) : (
        <p className="success-message">Registration received!</p>
      )}
    </div>
  );
};

export default RegisterSectionComponent;
