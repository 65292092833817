import React, { useState } from 'react';
import StepsSectionComponent from '../StepsSectionComponent/StepsSectionComponent';
import ListSectionComponent from '../ListSectionComponent/ListSectionComponent';
import LinksSectionComponent from '../LinksSectionComponent/LinksSectionComponent';
import TextSectionComponent from '../TextSectionComponent/TextSectionComponent';
import TextCentSectionComponent from '../TextCentSectionComponent/TextCentSectionComponent';
import RegisterSectionComponent from '../RegisterSectionComponent/RegisterSectionComponent';
import CareShareRepairComponent from '../CareShareRepairComponent/CareShareRepairComponent';
import './StoryComponent.css';

const StoryComponent = ({ story }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="story-component">
      <div className="story-header" onClick={toggleExpand}>
        <h2>{story.title}</h2>
        <span>{isExpanded ? '-' : '+'}</span>
      </div>
      { isExpanded && (
        <div className="story-content">
          {story['sectionComponents'].map((section, index) => {
            switch (section.name) {
              case 'stepsSectionComponent':
                return <StepsSectionComponent key={index} data={section.data} />;
              case 'listSectionCompenent':
                return <ListSectionComponent key={index} data={section.data} />;
              case 'linksSectionComponent':
                return <LinksSectionComponent key={index} data={section.data} />;
              case 'textSectionComponent':
                return <TextSectionComponent key={index} data={section.data} />;
              case 'textCentSectionComponent':
                return <TextCentSectionComponent key={index} data={section.data} />;
                case 'registerSectionComponent':
                return <RegisterSectionComponent key={index} data={section.data} />;
              case 'CareShareRepairComponent':
                return <CareShareRepairComponent key={index} />;
              default:
                return null;
            }
          })}
        </div>
      )}
    </div>
  );
};

export default StoryComponent;
